<template>
	<div>
		<CCard class="p-4">
			
		<CRow>
			<CCol lg="3">
				 <div class='demo-app-sidebar'>
				
					<div class='demo-app-sidebar-section'>
						<h4>All Events ({{ currentEvents.length }})</h4>
						<ul class="list-group" style="max-height: 700px;
  							overflow-y: scroll;">
						<li class="list-group-item" v-for='(event, index) of currentEvents' :key="index">
							<b style="font-size:12px">{{ event.startStr | dateFormat }}</b>
							<p class="mb-0">{{ event.title }}</p>
							<i>{{ event.extendedProps.holiday_type }}</i>
						</li> 
						</ul>
					</div>
					</div>
			</CCol>
			<CCol lg="9">
				<FullCalendar :options="calendarOptions" >
						<template v-slot:eventContent='arg' :class="'sdsad'">
					<b>{{ arg.event.title }} </b> 
					<br>
					<i>{{ arg.event.extendedProps.holiday_type }}</i>
					</template>
				</FullCalendar>
			</CCol>
			
			
		</CRow>
		</CCard>
		<CModal :title="selected_date" :show.sync="showEventModal" color="info"> 
			<CRow>
				<CCol>
					<form>
					<div class="form-group">
						<label for="email">Title <span class="text-danger">*</span></label>
						<input type="text" class="form-control" v-model="title">
					</div>
					<div class="form-group">
						<label for="pwd">Holiday Type</label>
						<select class="form-control" v-model="type">
							<option value="Regular">Regular</option>
							<option value="Special">Special</option>
						</select>
					</div>
					</form>
				</CCol>
			</CRow>
			<div slot="footer" class="w-100">
				<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="closeModal">
					Close
				</CButton> 
				<button size="sm" v-if="is_saving" class="btn btn-success" type="button" disabled>
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					<span class="sr-only">Saving...</span>
				</button>
				<template>
					<CButton size="sm" v-if="!is_saving" style="border-radius: .2rem; color: white;" :color="selected_id ? 'primary' : 'info'" class="ml-1 mr-1 float-right" @click="saveEvent">
						{{ selected_id ? 'Update' : 'Save'}}
					</CButton>
				</template>
				<template v-if="selected_id">
					<CButton size="sm" v-if="!is_saving" style="border-radius: .2rem; color: white;" color="warning" class="ml-1 mr-1 float-right" @click="saveEvent('delete')">
						<i class="fa fa-trash"></i>	
					</CButton>	
				</template>
				  
			</div>
    	</CModal>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import 'regenerator-runtime/runtime';
import { INITIAL_EVENTS, createEventId } from './event-utils'
export default {
	name: 'Calendar',
	watch: {
		showEventModal(val) {
			if(config.getPermission('working_calendar').create)
			if(!val) {
				this.selected_id = null;
				this.title = null;
				this.selected_date = null;
				this.type = 'Regular';
			}
		}
	},
	mounted(){  
		this.getEvents();
	},
	computed: {
		is_valid() {
			return this.transferParam.transfer_to && this.transferParam.transfer_date
		}
	},
	filters: {
		dateFormat(val) {
			if(val) {
				return moment(val).format('MMM. DD, YYYY')
			}
			return ''
		}
	},
	data(){
		return{
			config,
			calendarOptions: {
				headerToolbar: {
					left: 'title',
					center: '',
					right: 'prev,next today'
				},
				 initialEvents: INITIAL_EVENTS,
				plugins: [ dayGridPlugin, interactionPlugin ],
				initialView: 'dayGridMonth',
				dateClick: this.handleDateClick,
				weekends: true,
				select: this.handleDateSelect,
				eventsSet: this.handleEvents,
				editable: true,
				// selectable: true,
				selectMirror: true,
				dayMaxEvents: true,
				events: [
					
				],
				eventClick: this.eventClickHandler
			},
			is_update_deleted: false,
			showEventModal: false,
			title : null,
			selected_date : null,
			type : 'Regular',
			is_saving: false,
			selectInfo: {},
			currentEvents: [],
			selected_id : null
		}
	},
	name: 'Tables',
	components: {FullCalendar},
	methods: {
		// for multiple date
		// handleDateSelect(selectInfo) {
		// 	this.showEventModal = true;
		// 	this.selected_date = moment(selectInfo.startStr.date).format('YYYY-MM-DD');
		// 	this.selectInfo = selectInfo;
			
		// },
		handleDateClick(arg) {
			this.showEventModal = true;
			this.selected_date = moment(arg.date).format('YYYY-MM-DD');
			console.log(arg)
			this.selected_id = null;
	  
		},
		eventClickHandler(ev) {
			console.log(ev.event)
			let arg = ev.event;
			this.showEventModal = true;
			this.selected_date = moment(arg.start).format('YYYY-MM-DD');
			this.title = arg?.title ?? '';
			this.type = arg.extendedProps?.holiday_type ?? '';
			this.selected_id = arg.id;
		},
		saveEvent(action = null) {			
			if(this.title === null || this.title === '' || this.title.value === 0){
				Swal.fire({
					title: 'Error!',
					text: "Title is empty, Enter a title.",
					icon: 'warning', 
				})
				// this.showEventModal = false;
				this.is_saving = false;			
			}else {															
					this.showEventModal = false;					
					this.is_saving = true;
					console.log(this.selected_id);
					if(action == 'delete'){
						Swal.fire({
							title: 'Success',
							text: "Successfully Deleted.",
							icon: 'success', 
						})
					}else if (this.selected_id != null){
						Swal.fire({
							title: 'Success',
							text: "Successfully update event.",
							icon: 'success', 
						})
					}else {
						Swal.fire({
							title: 'Success',
							text: "Successfully add event.",
							icon: 'success', 
						})
					}												
					axios.post(config.api_path+'/calendar/save-event',{
					title : this.title,
					date : this.selected_date,
					type : this.type,
					id : this.selected_id,
					is_delete : action == 'delete' ? 1 : 0,									
				}).then(res => {					
					const {id} = res.data;										
					this.getEvents()
					// this.calendarOptions.events.push({
					// 	id,
					// 	title: this.title,
					// 	date : this.selected_date,
					// 	type : this.type
					// })								
					this.showEventModal = false;
					this.is_saving = false;					

				})
			}
		},
		closeModal() {
			this.showEventModal = false;
		},
		getEvents() {
			axios.get(config.api_path+'/calendar/all-event').then(res => {
				const {data} = res.data;
				this.calendarOptions.events = data.map(item => {
					item.date = item.holiday_date ? moment(item.holiday_date).format('YYYY-MM-DD') : '';
					return item;
				});
			})
		},
		handleEvents(events) {
			this.currentEvents = events
		}
	}
}
</script>
<style lang='css'>

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
  
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc { /* the calendar root */
  max-width: 1400px;
  /* margin: 0 auto; */
}
</style>